$(function () {
    $('body').on('click.modal.data-api', '[data-close="modal"]', function (e) {
        var $this = $(this), href = $this.attr('href'), $target = $($this.attr('data-target') || (href && href.replace(/.*(?=#[^\s]+$)/, ''))); //strip for ie7
        e.preventDefault();
        if ($this.attr('data-toggle') && $('.modal:visible').is($target)) {
            return;
        }
        $('.modal:visible').modal('hide');
    });
    $('body').on('click.bs.collapse.data-api', '[data-close="collapse"]', function (e) {
        var $this = $(this), href = $this.attr('href'), $target = $($this.attr('data-target') || (href && href.replace(/.*(?=#[^\s]+$)/, ''))); //strip for ie7
        if (!$this.attr('data-target'))
            e.preventDefault();
        $target.collapse('hide');
    });
    $('body').on('click.bs.data-api', '[data-toggle="div"]', function (e) {
        var $this = $(this), href = $this.attr('href'), $target = $($this.attr('data-target') || (href && href.replace(/.*(?=#[^\s]+$)/, ''))); //strip for ie7
        if (!$this.attr('data-target'))
            e.preventDefault();
        $target.toggleClass('hidden');
        if ($target.hasClass('hidden')) {
            $target.find('.scrollbar').hide();
        }
        else {
            $target.find('.scrollbar').show();
        }
        $this.toggleClass('hidden');
    });
});
